import React from 'react';
import Layout from '../components/Layout';
import { Typography } from '@material-ui/core';

const ReturnConfirm = () => {
  return (
    <Layout pageName="ReturnConfirm">
      <Typography variant="h1">Nous sommes désolée ne ne pas avoir satisfait tes attentes... </Typography>
      <Typography variant="h2">On fait au plus vite pour te rembourser</Typography>
    </Layout>
  );
};
export default ReturnConfirm;